import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'


// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// 调试本地api
process.env.VUE_APP_BASE_API == '/test-api'


// response interceptor
// service.interceptors.response.use(
  
  // response => {
  //   // window.responseList.push(response)
  //   console.log(response,"response")
  //   const res = response.data
  //   // if the custom code is not 200, it is judged as an error.
  //   if (res.code == 401) {
  //     Message({
  //       message: res.msg || 'Error',
  //       type: 'error',
  //       duration: 5 * 1000
  //     })
  //     return Promise.reject(new Error(res.msg || 'Error'))
  //   }
  //   if(res.code == 403){
  //     // 判断是否为菜单树接口
  //     // url: "/test-api/backend/menus/tree"
  //     if(response.config.url.indexOf("/menus/tree") == -1){
  //       getToken() && store.commit('app/DEL_CASH_VIEWS', {name: router.app.$route.name, isSelf: true})
  //       Message({
  //         message: '没有权限访问',
  //         type: 'error',
  //         duration: 5 * 1000
  //       })
  //     }else{
  //       logout()
  //       Message({
  //         message: '该用户没有配置角色',
  //         type: 'error',
  //         duration: 5 * 1000
  //       })
  //     }
  //     return Promise.reject(new Error(res.msg || 'Error'))
  //   }
    
  //   return res
  // },
  // error => {
  //   console.log('err' + error) // for debug
  //   if(!error.response)return Message({
  //     message: "请求超时了",
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   const status = error.response.status
  //   // 请求成功
  //   if(status == 401){
  //     console.log(router.app.$route)
  //     logout()
  //     store.dispatch("user/disabledMsg")
  //     // return Message({
  //     //   message: error.response.data.msg || '用户身份无法通过校验',
  //     //   type: 'error',
  //     //   duration: 5 * 1000
  //     // })
  //     return Promise.reject(new Error(error.response.data.msg || 'Error'))
  //   }
  //   if(status == 403){
  //     // 判断是否为菜单树接口
  //     // url: "/test-api/backend/menus/tree"
  //     if(response.config.url.indexOf("/menus/tree") == -1){
  //       getToken() && store.commit('app/DEL_CASH_VIEWS', {name: router.app.$route.name, isSelf: true})
  //       Message({
  //         message: '没有权限访问',
  //         type: 'error',
  //         duration: 5 * 1000
  //       })
  //     }else{
  //       logout()
  //       Message({
  //         message: '该用户没有配置角色',
  //         type: 'error',
  //         duration: 5 * 1000
  //       })
  //     }
  //     return Promise.reject(new Error(res.msg || 'Error'))
  //   }
  //   // 请求失败
  //   Message({
  //     message: error.response.message || error.response.msg || '服务器错误',
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   return Promise.reject(error)
  // }
// )


export default service
