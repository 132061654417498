import request from '@/utils/request'

// 留言
export function leaveMessage(data) {
  return request({
    url: '/leave_message',
    method: 'post',
    data
  })
}
