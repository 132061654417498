<template>
  <div>
    <message-box :show="messageShow" :type="messageType" :msg="messageTitle"/>
    <headerImg :src="banner" height="640" />
    <div class="content">
      <img src="@/assets/images/contactUs.png" alt="">
      <baidu-map class="map" :center="markerPoint" :zoom="16"> 
          <bm-marker :position="markerPoint" @click="infoWindowOpen"></bm-marker>
          <bm-info-window :position="{lng: 114.067925,lat: 22.579717}" title="深圳多知能源物联有限公司" :show="show" style="margin-bottom: 10px;font-size:14px">
              电话：400-9699-225 <br>
              地址：深圳市福田区中康路新一代产业园4栋22楼
          </bm-info-window>
      </baidu-map>
      <div class="leaving-message">
        <div>在线留言</div>
        <div class="left" :class="rules.message && 'text-err'">
          <textarea v-model="post.message" placeholder="留言内容" cols="30" rows="10"></textarea>
        </div>
        <div class="right">
          <div :class="rules.name && 'text-err'">
            <input type="text" placeholder="姓名" v-model="post.name">
          </div>
          <div :class="rules.phone && 'text-err'">
            <input type="text" placeholder="电话" v-model="post.phone">
          </div>
          <div :class="rules.address && 'text-err'">
            <input type="text" placeholder="城市" v-model="post.address">
          </div>
          <div :class="rules.code && 'text-err'">
            <input type="text" placeholder="验证码" v-model="code" maxlength="4" @keyup.enter="submit">
            <div @click="getCode"><identify :contentHeight="45" :identifyCode="identifyCode"/></div>
            <div @click="submit">提交</div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import headerImg from "@/components/headerImg.vue"
import identify from "@/components/identify.vue"
import banner from "@/assets/images/banner4.png"
import {leaveMessage} from "@/api/leaveMessage"
var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678深圳多知能源物联有限公司有限公司前端开发工程师股市大涨华秋物联网招聘深圳市福田区新一代产业园';

export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg, identify},
  data(){
    return {
      banner,
      markerPoint: {
        lng: 114.067925,
        lat: 22.578717
      },
      show: true,
      identifyCode: "",
      post: {
        name: "",
        phone: "",
        address: "",
        message: "",
      },
      code: "",
      rules: {
        name: null,
        phone: null,
        address: null,
        message: null,
        code: null,
      },
      messageShow: false,
      messageType: "success",
      messageTitle: "",
      messageTime: null,
    }
  },
  created(){
    this.getCode()
  },
  methods: {
    $Msg(messageTitle, messageTime = 1000, messageType = "success"){

      this.messageTime && clearTimeout(this.messageTime)
      this.messageType = messageType
      this.messageTitle = messageTitle
      this.messageShow = true
      console.log(654, this.messageShow)
      this.messageTime = setTimeout(() => {
        this.messageShow = false
        clearTimeout(this.messageTime)
      }, messageTime)
      
    },
    infoWindowOpen(){
      this.show = !this.show
    },
    getCode(){
      let code = ''
      for(let i = 0; i < 4; i++){
        code += $chars.charAt(Math.floor(Math.random() * $chars.length))
      }
      this.identifyCode = code
    },
    async submit(){
      // if(this.code.toUpperCase() != this.identifyCode.toUpperCase())return this.rules.code = true
      const post = this.post
      if(this.checkRules())return false
      // if(!post.name)return this.rules.name = true
      // if(!post.phone)return this.rules.phone = true
      // if(!post.address)return this.rules.address = true
      // if(!post.message)return this.rules.message = true
      const {data: {code, msg}} = await leaveMessage(post)
      if(code == 200)return this.$Msg("提交成功", 2000)
      console.log(code, msg,"code, msg")
      this.$Msg(msg, 2000, "danger")
    },
    checkRules(){

      const post = this.post
      Object.keys(this.rules).map(key => this.rules[key] = false)
      if(this.code.toUpperCase() != this.identifyCode.toUpperCase())this.rules.code = true
      if(!post.name)this.rules.name = true
      if(!post.phone)this.rules.phone = true
      if(!post.address)this.rules.address = true
      if(!post.message)this.rules.message = true

      return Object.keys(this.rules).find(key => this.rules[key])
    }
  }
}
</script>

<style lang="scss" scoped>
.map{
  width: 1200px;
  height: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  ::v-deep .anchorBL{
    display: none;
  }
  ::v-deep .BMap_bubble_title{
    color: #CC5522;
  }
  ::v-deep .BMap_bubble_content{
    white-space: nowrap;
  }
}
.leaving-message{
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  input{
    line-height: 45px;
    padding:  0 20px;
  }
  textarea{
    padding: 15px 20px;
  }
  input, textarea{
    outline: none;
    border: 0;
    background: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
  }
  & > div:nth-child(1){
    width: 100%;
    color: #848282;
    line-height: 60px;
    font-weight: 600;
    font-size: 20px;
  }
  .left{
    width: 56%;
    display: flex;
    textarea{
      width: 100%;
      min-height: 220px;
    }
  }
  .right{
    width: 40%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(3){
      input{
        width: 100%;
        box-sizing: border-box;
      }
    }
    & > div:nth-child(4){
      display: flex;
      align-items: center;
      & > div:nth-child(2){
        margin: 0 10px;
      }
      & > div:nth-child(3){
        width: 120px;
        background: #25b7f1;
        line-height: 45px;
        display: flex;
        color: white;
        border-radius: 6px;
        place-content: center;
        cursor: pointer;
      }
      & > div:nth-child(3):active{
        opacity: 0.4;
      }
    }
  }
  .text-err{
    position: relative;
    & > input, & > textarea{
      border: 1px solid rgb(236, 87, 87);
    }
    &::after{
      position: absolute;
      left: 10px;
      top: calc(100% + 3px);
      color: rgb(236, 87, 87);
      font-weight: 600;
      font-size: 12px;

    }
  }
  .text-err:nth-child(1)::after{
    content: "请填写姓名信息";
  }
  .text-err:nth-child(2)::after{
    content: "请填写电话信息";
  }
  .text-err:nth-child(3)::after{
    content: "请填写城市信息";
  }
  .text-err:nth-child(4)::after{
    content: "请填写验证码信息";
  }
}
@media screen and (max-width: 600px) {
  .content{
    max-width: 100%!important;
    & > img, .map{
      width: 100%!important;
    }
    .leaving-message{
      .left, .right{
        width: 100%;
        margin-bottom: 10px;
        input{
          margin-bottom: 10px;
        }
        div{
          flex-wrap: wrap;
        }
      }
    }
  }
}

</style>